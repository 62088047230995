// Dashboard/Dashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import DashboardTable from './DashboardTable';
import SharedPieChart from './PieCharts/SharedPieCharts';
import TimelineFilter from './TimelineFilter';
import useFilteredChangeOrderStats from './useFilteredChangeOrderStats';
import useChangeOrdersList from './useChangeOrdersList';
import OnboardingModal from './OnboardingModal';
import API_BASE_URL from '../../constants/apiConfig';
import './PieCharts/SharedPieCharts.css';
import './TimelineFilter.css';
import './Dashboard.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);
  const { changeOrders, error: changeOrdersError, loading: changeOrdersLoading } = useChangeOrdersList(user?.customer_id);
  const { 
    timeRange, 
    filteredChangeOrdersStats, 
    pieChartData, 
    handleTimeRangeChange,
    error: statsError
  } = useFilteredChangeOrderStats(user?.customer_id);

  useEffect(() => {
    const checkOnboardingStatus = async () => {
      if (user?.customer_id) {
        try {
          const response = await axios.get(`${API_BASE_URL}/check-onboarding-status`, {
            params: { customer_id: user.customer_id },
            withCredentials: true,
          });
          console.log('Onboarding status response:', response.data);
          if (response.data.success) {
            setShowOnboardingModal(!response.data.onboarded);
          }
        } catch (error) {
          console.error('Error checking onboarding status:', error);
        }
      }
    };

    checkOnboardingStatus();
  }, [user]);

  const handleCreateNewOrder = () => {
    navigate('/change-order/new');
  };

  const handleGoToAssistant = (message = '') => {
    const encodedMessage = encodeURIComponent(message);
    navigate(`/assistant?message=${encodedMessage}`);
  };

  const formatNumber = (num) => {
    return num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  const renderStatistics = () => {
    const stats = filteredChangeOrdersStats;
    
    return (
      <ul className="list-unstyled mt-3 stats-list">
        {Object.entries(stats).filter(([key]) => key !== 'Total' && key !== 'Incomplete').map(([key, value]) => (
          <li key={key}>
            <span className="stat-label">{key}:</span>
            <span className="stat-count">{value.count.toLocaleString()}</span>
            <span className="stat-value">${formatNumber(value.totalValue)}</span>
          </li>
        ))}
        <li className="total-separator"></li>
        <li className="total-row">
          <span className="stat-label">Total:</span>
          <span className="stat-count">{stats.Total.count.toLocaleString()}</span>
          <span className="stat-value">${formatNumber(stats.Total.totalValue)}</span>
        </li>
      </ul>
    );
  };

  if (changeOrdersError || statsError) {
    return <div>Error loading change order data. Please try again later.</div>;
  }

  if (changeOrdersLoading) {
    return <div>Loading change orders...</div>;
  }

  const renderChangeOrderSections = () => {
    const statuses = ['Incomplete', 'Unexecuted'];

    return (
      <div className="change-orders-section">
        {Object.entries(changeOrders).map(([region, regionOrders]) => (
          <div key={region} className="mb-4">
            <h3 className="region-name">{region}</h3>
            {statuses.map(status => (
              <DashboardTable
                key={`${region}-${status}`}
                region={region}
                status={status}
                changeOrders={regionOrders}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="container mt-5 dashboard-container">
      <div className="card shadow">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h2>Dashboard</h2>
            <button className="btn btn-primary" onClick={handleCreateNewOrder}>
              Create New COR
            </button>
          </div>

          <div className="row mb-4">
            <div className="col-lg-8 mb-4 mb-lg-0">
              <div className="dashboard-card h-100">
                <h3 className="card-title">Change Order Status</h3>
                <div className="card-content pie-chart-container">
                  {pieChartData && (
                    <SharedPieChart 
                      data={pieChartData} 
                      tooltipFormatter={(value, name, props) => {
                        return [
                          `Count: ${props.payload.count.toLocaleString()}`,
                          `Total Value: $${formatNumber(parseFloat(props.payload.totalValue))}`,
                          `Name: ${name}`
                        ];
                      }}
                    />
                  )}
                  {filteredChangeOrdersStats.Total.count === 0 && (
                    <p className="text-center mt-3">No change orders available</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dashboard-card h-100">
                <h3 className="card-title">Change Order Metrics</h3>
                <div className="card-content statistics-container">
                  <TimelineFilter 
                    selectedRange={timeRange} 
                    onFilterChange={handleTimeRangeChange} 
                  />
                  {renderStatistics()}
                </div>
              </div>
            </div>
          </div>

          {renderChangeOrderSections()}
        </div>
      </div>
      
      <OnboardingModal 
        show={showOnboardingModal} 
        onGoToAssistant={() => handleGoToAssistant("Let's start onboarding")}
      />
    </div>
  );
};

export default Dashboard;
/* Used by: modules/ChangeOrderComponents/ChangeOrder.js */

// src/modules/ChangeOrderComponents/ScheduleExtension/ScheduleExtension.js
import React from 'react';
import { useSelector } from 'react-redux';
import './ScheduleExtension.css';

const ScheduleExtension = () => {
  const { changeOrderDetails } = useSelector((state) => state.changeOrder);
  const { schedule_extension } = changeOrderDetails.bigDict;

  if (!schedule_extension) return null;

  const formatExtensionTime = (extension) => {
    const parts = [];
    if (extension.Days) parts.push(`${extension.Days} Day(s)`);
    if (extension.Weeks) parts.push(`${extension.Weeks} Week(s)`);
    if (extension.Months) parts.push(`${extension.Months} Month(s)`);
    return parts.join(', ');
  };

  return (
    <div className="schedule-extension mb-4">
      <h5 className="mb-3">Schedule Extension Needed:</h5>
      <p>{formatExtensionTime(schedule_extension)}</p>
    </div>
  );
};

export default ScheduleExtension;
// Dashboard/OnboardingModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const OnboardingModal = ({ show, onGoToAssistant }) => {
  return (
    <Modal show={show} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Account Not Onboarded</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Your account has not been onboarded yet. Please complete the onboarding process to access all features.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onGoToAssistant}>
          Start Onboarding
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OnboardingModal;
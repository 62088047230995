/* Used by: modules/ChangeOrderComponents/ChangeOrder.js */

// src/modules/ChangeOrderComponents/JobInfo/JobInfo.js
import React from 'react';
import './JobInfo.css';

const JobInfo = ({ job }) => {
  if (!job) return null;

  const { jobName, gcName, description } = job;

  return (
    <div className="job-info mb-4">
      <h5 className="mb-3">Job Information</h5>
      <p><strong>Job Name:</strong> {jobName}</p>
      <p><strong>General Contractor:</strong> {gcName}</p>
      <p><strong>Description:</strong> {description}</p>
    </div>
  );
};

export default JobInfo;

/* Used by: modules/ChangeOrderComponents/ChangeOrder.js */

// src/modules/ChangeOrderComponents/CustomerInfo/CustomerInfo.js
import React from 'react';
import { useSelector } from 'react-redux';
import './CustomerInfo.css';

const CustomerInfo = () => {
  const { changeOrderDetails } = useSelector((state) => state.changeOrder);
  const {
    customerName,
    customerPhone,
    customerAddressStreet,
    customerAddressCity,
    customerAddressState,
    customerAddressZip,
  } = changeOrderDetails;

  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const country_code_removed = cleaned.length === 11 && cleaned.startsWith('1') ? cleaned.substring(1) : cleaned;

    const match = country_code_removed.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumberString;
  };

  const formattedPhone = formatPhoneNumber(customerPhone);
  const address = `${customerAddressStreet}, ${customerAddressCity}, ${customerAddressState} ${customerAddressZip}`;

  return (
    <div className="customer-info mb-4">
      <h5 className="mb-3">Customer Information</h5>
      <p><strong>Name:</strong> {customerName}</p>
      <p><strong>Phone:</strong> {formattedPhone}</p>
      <p><strong>Address:</strong> {address}</p>
    </div>
  );
};

export default CustomerInfo;
/* Used by: modules/UsersPage/UsersPage.js */

// src/modules/UsersPage/EditableUsersTable.js
import React from 'react';
import { useSelector } from 'react-redux';

const formatPhoneNumber = (value) => {
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumber.startsWith('1')) {
    if (phoneNumberLength < 5) return phoneNumber.slice(1);
    if (phoneNumberLength < 8) return `(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4)}`;
    return `(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
  } else {
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
};

const EditableEmployeeTable = ({
  employees,
  setEmployees,
  newEmployees,
  setNewEmployees,
  removeEmployee,
}) => {
  const currentUserPhoneNumber = useSelector((state) => state.user.user?.phone);
  console.log('currentUserPhoneNumber', currentUserPhoneNumber);

  const handleEmployeeChange = (phoneNumber, field, value) => {
    if (field !== 'phone_number') {
      const updatedEmployees = employees.map((employee) =>
        employee.phone_number === phoneNumber ? { ...employee, [field]: value } : employee
      );
      setEmployees(updatedEmployees);
    }
  };

  const handleNewEmployeeChange = (index, field, value) => {
    const updatedNewEmployees = [...newEmployees];
    if (field === 'phone_number') {
      updatedNewEmployees[index][field] = formatPhoneNumber(value);
    } else {
      updatedNewEmployees[index][field] = value;
    }
    setNewEmployees(updatedNewEmployees);
  };

  const removeNewEmployee = (index) => {
    const updatedNewEmployees = [...newEmployees];
    updatedNewEmployees.splice(index, 1);
    setNewEmployees(updatedNewEmployees);
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Phone Number</th>
          <th>Language</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {employees.map((employee) => (
          <tr key={employee.phone_number}>
            <td>
              <input
                type="text"
                className="form-control"
                value={employee.first_name}
                onChange={(e) =>
                  handleEmployeeChange(employee.phone_number, 'first_name', e.target.value)
                }
                required
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                value={employee.last_name}
                onChange={(e) =>
                  handleEmployeeChange(employee.phone_number, 'last_name', e.target.value)
                }
                required
              />
            </td>
            <td>
              <span>{formatPhoneNumber(employee.phone_number)}</span>
            </td>
            <td>
              <select
                className="form-control"
                value={employee.language}
                onChange={(e) =>
                  handleEmployeeChange(employee.phone_number, 'language', e.target.value)
                }
              >
                <option value="English">English</option>
                <option value="Spanish">Spanish</option>
              </select>
            </td>
            <td>
              {employee.phone_number !== currentUserPhoneNumber && (
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => removeEmployee(employee.phone_number)}
                >
                  Remove
                </button>
              )}
            </td>
          </tr>
        ))}
        {newEmployees.map((employee, index) => (
          <tr key={index}>
            <td>
              <input
                type="text"
                className="form-control"
                value={employee.first_name}
                onChange={(e) => handleNewEmployeeChange(index, 'first_name', e.target.value)}
                required
              />
            </td>
            <td>
              <input
                type="text"
                className="form-control"
                value={employee.last_name}
                onChange={(e) => handleNewEmployeeChange(index, 'last_name', e.target.value)}
                required
              />
            </td>
            <td>
              <input
                type="tel"
                className="form-control"
                value={formatPhoneNumber(employee.phone_number)}
                onChange={(e) => handleNewEmployeeChange(index, 'phone_number', e.target.value)}
                required
              />
            </td>
            <td>
              <select
                className="form-control"
                value={employee.language}
                onChange={(e) => handleNewEmployeeChange(index, 'language', e.target.value)}
              >
                <option value="English">English</option>
                <option value="Spanish">Spanish</option>
              </select>
            </td>
            <td>
              <button className="btn btn-danger btn-sm" onClick={() => removeNewEmployee(index)}>
                Remove
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EditableEmployeeTable;
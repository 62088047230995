/* Used by: modules/ChangeOrderComponents/ChangeOrder.js */

// src/modules/ChangeOrderComponents/Categories/Categories.js
import React from 'react';
import { useSelector } from 'react-redux';
import './Categories.css';

const Categories = () => {
  const { changeOrderDetails } = useSelector((state) => state.changeOrder);
  const { categories, sections, tax_rate } = changeOrderDetails.bigDict;
 const taxRate = tax_rate || 0;

  if (!categories && !sections) return null;

  const formatCurrency = (amount) => {
    if (amount === null || amount === undefined) return 'N/A';
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const renderCategorySection = (categoryData, categoryName, taxRate) => {
    if (!categoryData || typeof categoryData !== 'object' || Object.keys(categoryData).length === 0) {
      return null;
    }

    const subtotal = Object.entries(categoryData).reduce((total, [_, itemDetails]) => {
      return total + (itemDetails.quantity * itemDetails.cost_per_unit);
    }, 0);

    const tax = categoryName === 'materials' ? subtotal * taxRate : 0;
    const totalWithTax = subtotal + tax;

    return (
      <div className={`category-section mb-3 ${categoryName.toLowerCase()}`}>
        <h3>{categoryName}</h3>
        <table className="table">
          <thead>
            <tr>
              <th>Item</th>
              <th>$ / Unit</th>
              <th>Quantity</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(categoryData).map(([itemName, itemDetails]) => (
              <tr key={itemName}>
                <td>{itemName}</td>
                <td>{`${formatCurrency(itemDetails.cost_per_unit)} / ${itemDetails.unit || 'ea'}`}</td>
                <td>{itemDetails.quantity}</td>
                <td>{formatCurrency(itemDetails.quantity * itemDetails.cost_per_unit)}</td>
              </tr>
            ))}
          </tbody>
          <tfoot className="categories-foot">
            <tr className="subtotal-row">
              <td colSpan="2"></td>
              <td>Subtotal</td>
              <td>{formatCurrency(subtotal)}</td>
            </tr>
            {categoryName === 'materials' && (
              <>
                <tr className="tax-row">
                  <td colSpan="2"></td>
                  <td>Tax & Shipping ({(taxRate * 100).toFixed(2)}%)</td>
                  <td>{formatCurrency(tax)}</td>
                </tr>
                <tr className="total-row">
                  <td colSpan="2"></td>
                  <td>Total</td>
                  <td>{formatCurrency(totalWithTax)}</td>
                </tr>
              </>
            )}
          </tfoot>
        </table>
      </div>
    );
  };

  const renderCategories = (categoriesData, taxRate) => (
    <>
      {['labor', 'materials', 'equipment', 'other'].map((categoryName) => {
        const categoryData = categoriesData[categoryName];
        if (categoryData && Object.keys(categoryData).length > 0) {
          return (
            <div key={categoryName} className={`category-section ${categoryName}`}>
              {renderCategorySection(categoryData, categoryName, taxRate)}
            </div>
          );
        }
        return null;
      })}
    </>
  );

  return (
    <section className="categories">
      {categories && renderCategories(categories, taxRate)}
      {sections && Object.entries(sections).map(([sectionName, sectionData]) => (
        <div key={sectionName} className="section">
          <h2>{sectionName}</h2>
          {renderCategories(sectionData.categories, taxRate)}
        </div>
      ))}
    </section>
  );
};

export default Categories;
import { useState } from 'react';
import axios from 'axios';
import API_BASE_URL from '../../../constants/apiConfig';

const useUpdateStatus = (user) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateStatus = async (statusType, idNumber, newStatus) => {
    setIsLoading(true);
    setError(null);
    try {
      await axios.post(`${API_BASE_URL}/status-update`, {
        statusType,
        idNumber,
        status: newStatus,
        customer_id: user.customer_id
      });
      setIsLoading(false);
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
      setIsLoading(false);
    }
  };

  const deleteChangeOrder = async (corNumber) => {
    setIsLoading(true);
    setError(null);
    try {
      await axios.post(`${API_BASE_URL}/delete-change-order`, {
        customer_id: user.customer_id,
        cor_number: corNumber
      });
      setIsLoading(false);
    } catch (error) {
      setError(error.response ? error.response.data : error.message);
      setIsLoading(false);
    }
  };

  return { updateStatus, deleteChangeOrder, isLoading, error };
};

export default useUpdateStatus;

// src/modules/ChangeOrderComponents/JobInfo/EditableJobInfo.js
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateEditValues } from '../redux/changeOrderActions';
import './JobInfo.css';

const MAX_DESCRIPTION_LENGTH = 255;

const EditableJobInfo = ({ onValidationChange }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editValues, jobOptions } = useSelector((state) => state.changeOrder);
  const [errors, setErrors] = useState({});
  const [isDescriptionTooLong, setIsDescriptionTooLong] = useState(false);

  const handleJobNameChange = (newJobName) => {
    setErrors(prev => ({ ...prev, job_name: '' }));
    if (newJobName === 'add-job') {
      navigate('/jobs');
    } else {
      dispatch(updateEditValues({ ...editValues, job_name: newJobName }));
    }
  };

  const handleDescriptionChange = (newDescription) => {
    setErrors(prev => ({ ...prev, desc: '' }));
    if (newDescription.length <= MAX_DESCRIPTION_LENGTH) {
      dispatch(updateEditValues({ ...editValues, desc: newDescription }));
      setIsDescriptionTooLong(false);
    } else {
      setIsDescriptionTooLong(true);
    }
  };

  const gcName = jobOptions[editValues.job_name] || '';

  // Validation function
  const validate = useCallback(() => {
    const newErrors = {};
    if (!editValues.job_name) {
      newErrors.job_name = 'Please select a job';
    }
    if (!editValues.desc || editValues.desc.trim() === '') {
      newErrors.desc = 'Please enter a description';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [editValues.job_name, editValues.desc]);

  // Update parent component about validation state
  useEffect(() => {
    if (onValidationChange) {
      onValidationChange(validate);
    }
  }, [validate, onValidationChange]);

  return (
    <div className="job-info mb-4">
      <h5 className="mb-3">Job Information</h5>
      <div className="mb-3">
        <p className="info-flex">
          <strong>Job Name:</strong>
          <select
            className="form-select"
            value={editValues.job_name || ''}
            onChange={(e) => handleJobNameChange(e.target.value)}
          >
            <option value="">Select a job</option>
            {Object.keys(jobOptions).map((jobName) => (
              <option key={jobName} value={jobName}>
                {jobName}
              </option>
            ))}
            <option value="add-job">Add New Job</option>
          </select>
        </p>
        {errors.job_name && <p className="text-danger">{errors.job_name}</p>}
      </div>
      <div className="mb-3">
        <p>
          <strong>General Contractor:</strong> {gcName}
        </p>
      </div>
      <div className="mb-3">
        <p>
          <strong>Description:</strong>
          <textarea
            className="form-control"
            value={editValues.desc || ''}
            onChange={(e) => handleDescriptionChange(e.target.value)}
            maxLength={MAX_DESCRIPTION_LENGTH}
          />
        </p>
        {errors.desc && <p className="text-danger">{errors.desc}</p>}
        {isDescriptionTooLong && (
          <p className="text-danger">Description is too long (max 255 characters)</p>
        )}
        <p className="text-muted">
          {editValues.desc ? editValues.desc.length : 0}/{MAX_DESCRIPTION_LENGTH} characters
        </p>
      </div>
    </div>
  );
};

export default EditableJobInfo;
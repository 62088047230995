import { useState, useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../../constants/apiConfig';

const useChangeOrdersList = (customerId, jobName = null) => {
  const [changeOrders, setChangeOrders] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getChangeOrders = async () => {
      if (customerId) {
        setLoading(true);
        try {
          const params = { customer_id: customerId };
          if (jobName) params.job_name = jobName;

          const response = await axios.get(`${API_BASE_URL}/change-orders-list`, {
            params: params,
            withCredentials: true,
          });

          if (response.data.success) {
            console.log('Fetched change orders:', response.data.data);
            setChangeOrders(response.data.data);
            setError(null);
          } else {
            throw new Error(response.data.message);
          }
        } catch (error) {
          console.error('Error fetching change orders:', error);
          setError(error.message || 'An error occurred while fetching change orders');
          setChangeOrders({});
        } finally {
          setLoading(false);
        }
      }
    };

    getChangeOrders();
  }, [customerId, jobName]);

  return { changeOrders, error, loading };
};

export default useChangeOrdersList;
// ChangeOrderComponents/ChangeOrder.js

import React, { useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomerInfo from './CustomerInfo/CustomerInfo';
import JobInfo from './JobInfo/JobInfo';
import EditableJobInfo from './JobInfo/EditableJobInfo';
import Categories from './Categories/Categories';
import ScheduleExtension from './ScheduleExtension/ScheduleExtension';
import EditableScheduleExtension from './ScheduleExtension/EditableScheduleExtension';
import Totals from './Totals/Totals';
import EditButton from '../shared/EditButton';
import EditableCategories from './Categories/EditableCategories';
import EditableTotals from './Totals/EditableTotals';
import { jobNameAndGC } from './utils';
import useDownloadPdf from './useDownloadPdf';
import ManageAttachmentsModal from './ManageAttachmentsModal';
import './ChangeOrder.css';

const ChangeOrder = ({ user, initialCorNumber, loading, error, changeOrderDetails, jobOptions, isEditable, onEdit, onSave, onCancel, isNewOrder }) => {
  const { downloadPdf, isLoading: isPdfLoading } = useDownloadPdf();
  const [showManageAttachments, setShowManageAttachments] = useState(false);
  const validateJobInfo = useRef(null);

  if (loading) {
    return <div>Loading change order details...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!changeOrderDetails) {
    return <div>No change order details found.</div>;
  }

  const { jobName, gcName } = jobNameAndGC(changeOrderDetails?.bigDict, jobOptions);

  const jobDetails = {
    jobName,
    gcName,
    description: changeOrderDetails.bigDict.desc
  };

  const handleDownloadPdf = () => {
    if (user && user.customer_id && initialCorNumber) {
      downloadPdf({
        type: 'changeOrder',
        user: user,
        cor_number: initialCorNumber,
        change_orders_dict: null,
      });
    } else {
      console.error('Missing user/customer_id or cor_number for PDF download.');
    }
  };

  const handleManageAttachments = () => {
    setShowManageAttachments(true);
  };

  const handleValidationChange = (validationFunc) => {
    validateJobInfo.current = validationFunc;
  };

  const handleEdit = () => {
    onEdit();
  };

  const handleSave = () => {
    if (validateJobInfo.current && validateJobInfo.current()) {
      onSave();
    } else {
      console.log('Validation failed. Please check the form.');
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <div className="container mt-4 change-order-container">
      <div className="card custom-card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h1 className="card-title">Change Order Request</h1>
          <div className="button-group">
            <button onClick={handleManageAttachments} className="btn btn-secondary me-2">Manage Attachments</button>
            {!isEditable && (
              <>
                <button onClick={handleDownloadPdf} className="btn btn-primary me-2">
                  {isPdfLoading ? 'Downloading...' : 'Download PDF'}
                </button>
              </>
            )}
            <EditButton
              isEditable={isEditable}
              onEdit={handleEdit}
              onSave={handleSave}
              onCancel={handleCancel}
            />
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              {isEditable ? (
                <EditableJobInfo onValidationChange={handleValidationChange} />
              ) : (
                <JobInfo job={jobDetails} />
              )}
            </div>
            <div className="col-md-6">
              <CustomerInfo />
            </div>
          </div>

          {!isNewOrder && (
            <div className="cor-number-section mb-4">
              <h5>COR Number: {initialCorNumber}</h5>
            </div>
          )}

          {isEditable ? (
            <>
              <EditableCategories />
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                  <EditableTotals />
                </div>
              </div>
              <EditableScheduleExtension />
            </>
          ) : (
            <>
              <Categories />
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8">
                  <Totals />
                </div>
              </div>
              {changeOrderDetails.bigDict.schedule_extension && <ScheduleExtension />}
            </>
          )}
        </div>
      </div>
      <ManageAttachmentsModal 
        show={showManageAttachments} 
        onHide={() => setShowManageAttachments(false)} 
        user={user} 
        initialCorNumber={initialCorNumber} 
      />
    </div>
  );
};

export default ChangeOrder;
/* Used by: modules/UsersPage/UsersPage.js */

// src/modules/CompanyPage/EmployeeManagement/EmployeeTable.js
import React from 'react';

const formatPhoneNumber = (value) => {
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumber.startsWith('1')) {
    if (phoneNumberLength < 5) return phoneNumber.slice(1);
    if (phoneNumberLength < 8) return `(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4)}`;
    return `(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
  } else {
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
};

const EmployeeTable = ({ employees }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Phone Number</th>
          <th>Language</th>
        </tr>
      </thead>
      <tbody>
        {employees.map((employee) => (
          <tr key={employee.phone_number}>
            <td>{employee.first_name}</td>
            <td>{employee.last_name}</td>
            <td>{formatPhoneNumber(employee.phone_number)}</td>
            <td>{employee.language}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EmployeeTable;
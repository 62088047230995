// src/modules/ChangeOrderComponents/ManageAttachmentsModal.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Button, Table, Alert } from 'react-bootstrap';
import axios from 'axios';
import API_BASE_URL from '../../constants/apiConfig';
import './ManageAttachmentsModal.css';

const ManageAttachmentsModal = ({ show, onHide, user, initialCorNumber }) => {
  const [attachments, setAttachments] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);

  const fetchAttachments = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/change-order-attachments`, {
        params: {
          customer_id: user.customer_id,
          cor_number: initialCorNumber,
        },
      });
      setAttachments(response.data);
    } catch (error) {
      console.error('Error fetching attachments:', error);
    }
  }, [user.customer_id, initialCorNumber]);

  useEffect(() => {
    if (show) {
      fetchAttachments();
    }
  }, [show, fetchAttachments]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const validTypes = ['image/png', 'image/jpeg', 'application/pdf'];
    if (!validTypes.includes(file.type)) {
      setError('Only PNG, JPEG, and PDF files are allowed.');
      return;
    }

    setError('');
    setUploading(true);
    const formData = new FormData();
    formData.append('customer_id', user.customer_id);
    formData.append('cor_number', initialCorNumber);
    formData.append('file', file);

    try {
      await axios.post(`${API_BASE_URL}/api/upload-change-order-attachment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      fetchAttachments();
      setUploading(false);
    } catch (error) {
      console.error('Error uploading attachment:', error);
      setUploading(false);
    }
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleDelete = async (blobName) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-change-order-attachment`, {
        params: { blob_name: blobName, customer_id: user.customer_id },
      });
      fetchAttachments();
    } catch (error) {
      console.error('Error deleting attachment:', error);
    }
  };

  const handleDownload = async (blobName) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/download-change-order-attachment`, {
        params: { blob_name: blobName, customer_id: user.customer_id },
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', blobName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading attachment:', error);
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" className="manage-attachments-modal">
      <Modal.Header closeButton>
        <Modal.Title>Manage Attachments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="d-flex justify-content-between mb-3">
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Button variant="primary" onClick={handleUploadButtonClick} disabled={uploading}>
            {uploading ? 'Uploading...' : 'Upload'}
          </Button>
        </div>
        <div className="table-responsive">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Filename</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {attachments.map((attachment) => (
                <tr key={attachment.blobName}>
                  <td>{attachment.originalFileName}</td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <Button variant="danger" onClick={() => handleDelete(attachment.blobName)} className="me-2">
                        Delete
                      </Button>
                      <Button variant="secondary" onClick={() => handleDownload(attachment.blobName)}>
                        Download
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageAttachmentsModal;

// src/modules/ChangeOrderComponents/Categories/EditableCategories.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateEditValues } from '../redux/changeOrderActions';
import './Categories.css';

const EditableCategories = () => {
  const dispatch = useDispatch();
  const { editValues } = useSelector((state) => state.changeOrder);
  const { categories, sections, tax_rate } = editValues;
  const taxRate = parseFloat(tax_rate);

  const CATEGORY_ORDER = ['labor', 'materials', 'equipment', 'other'];

  const editModeStyles = {
    table: {
      tableLayout: 'auto',
    },
    buttonAction: {
      padding: '4px 8px',
      border: '1px solid #ccc',
      background: '#f9f9f9',
      cursor: 'pointer',
    },
  };

  const formatCurrency = (amount) => {
    if (amount === null || amount === undefined) return 'N/A';
    return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const calculateBaseCost = (categories, taxRate) => {
    return Object.entries(categories).reduce((total, [categoryName, category]) => {
      const categoryTotal = Object.values(category).reduce((itemTotal, item) => {
        const itemCost = item.quantity * item.cost_per_unit;
        return itemTotal + itemCost;
      }, 0);

      if (categoryName === 'materials') {
        const categoryTax = categoryTotal * taxRate;
        return total + categoryTotal + categoryTax;
      } else {
        return total + categoryTotal;
      }
    }, 0);
  };

  const handleCategoryChange = (categoryName, itemName, field, value) => {
    let parsedValue = value;
    
    if (field === 'cost_per_unit') {
      if (value === '-' || value === '') {
        parsedValue = value;
      } else {
        parsedValue = parseFloat(value);
      }
    } else if (field === 'quantity') {
      parsedValue = parseInt(value, 10);
    }

    let updatedCategories;

    if (field === 'itemName') {
      // Special handling for itemName change
      updatedCategories = {
        ...categories,
        [categoryName]: Object.entries(categories[categoryName]).reduce((acc, [key, val]) => {
          if (key === itemName) {
            acc[value] = val;
          } else {
            acc[key] = val;
          }
          return acc;
        }, {}),
      };
    } else {
      updatedCategories = {
        ...categories,
        [categoryName]: {
          ...categories[categoryName],
          [itemName]: {
            ...categories[categoryName][itemName],
            [field]: parsedValue,
          },
        },
      };
    }

    const newBaseCost = calculateBaseCost(updatedCategories, taxRate);

    const updatedTotalCosts = {
      ...editValues.total_costs,
      base_cost: newBaseCost,
    };

    dispatch(updateEditValues({ ...editValues, categories: updatedCategories, total_costs: updatedTotalCosts }));
    console.log('Updated editValues:', { ...editValues, categories: updatedCategories, total_costs: updatedTotalCosts });
  };

  const handleAddItem = (categoryName) => {
    const newItemName = `New Item ${Object.keys(categories[categoryName]).length + 1}`;
    const updatedCategories = {
      ...categories,
      [categoryName]: {
        ...categories[categoryName],
        [newItemName]: {
          id: `temp-${categoryName}-${newItemName}`,
          cost_per_unit: 0,
          unit: 'ea',
          quantity: 0,
        },
      },
    };
    dispatch(updateEditValues({ ...editValues, categories: updatedCategories }));
  };

  const handleRemoveItem = (categoryName, itemName) => {
    const updatedCategories = {
      ...categories,
      [categoryName]: Object.entries(categories[categoryName])
        .filter(([key]) => key !== itemName)
        .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
    };
    dispatch(updateEditValues({ ...editValues, categories: updatedCategories }));
  };

  const handleTaxRateChange = (value) => {
    const parsedValue = parseFloat(value);
    const updatedTaxRate = parsedValue / 100;

    const newBaseCost = calculateBaseCost(categories, updatedTaxRate);
    console.log('Updated basecost after tax adjustment:', newBaseCost);

    const updatedTotalCosts = {
      ...editValues.total_costs,
      base_cost: newBaseCost,
    };

    dispatch(updateEditValues({ ...editValues, tax_rate: updatedTaxRate, total_costs: updatedTotalCosts }));
  };

  const renderEditableCategorySection = (categoryData, categoryName) => {
    const subtotal = Object.entries(categoryData).reduce((total, [_, itemDetails]) => {
      return total + (itemDetails.quantity * parseFloat(itemDetails.cost_per_unit || 0));
    }, 0);

    const categoryEntries = Object.entries(categoryData);
    const decimalTaxRate = editValues.tax_rate || 0;
    const tax = categoryName === 'materials' ? subtotal * decimalTaxRate : 0;

    const totalWithTax = subtotal + tax;

    return (
      <div className={`category-section mb-3 ${categoryName.toLowerCase()}`}>
        <h3>{categoryName}</h3>
        <button onClick={() => handleAddItem(categoryName)} style={editModeStyles.buttonAction}>
          Add New Item
        </button>
        <table className="table" style={editModeStyles.table}>
          <thead>
            <tr>
              <th>Item</th>
              <th>$ / Unit</th>
              <th>Quantity</th>
              <th>Cost</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {categoryEntries.map(([itemName, itemDetails]) => (
              <tr key={itemDetails.id}>
                <td>
                  <input
                    type="text"
                    value={itemName}
                    onChange={(e) => handleCategoryChange(categoryName, itemName, 'itemName', e.target.value)}
                    className="form-control"
                  />
                </td>
                <td>
                  <div className="cost-unit-container">
                    <input
                      type="number"
                      value={itemDetails.cost_per_unit}
                      onChange={(e) => handleCategoryChange(categoryName, itemName, 'cost_per_unit', e.target.value)}
                      className="form-control cost-input"
                    />
                    <span className="divider">/</span>
                    <input
                      type="text"
                      value={itemDetails.unit}
                      onChange={(e) => handleCategoryChange(categoryName, itemName, 'unit', e.target.value)}
                      className="form-control unit-input"
                    />
                  </div>
                </td>
                <td>
                  <input
                    type="number"
                    value={itemDetails.quantity}
                    onChange={(e) => handleCategoryChange(categoryName, itemName, 'quantity', e.target.value)}
                    min="0"
                    className="form-control"
                  />
                </td>
                <td>{formatCurrency(itemDetails.quantity * parseFloat(itemDetails.cost_per_unit || 0))}</td>
                <td>
                  <button onClick={() => handleRemoveItem(categoryName, itemName)} style={editModeStyles.buttonAction}>
                    Remove
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="categories-foot">
            <tr className="subtotal-row">
              <td colSpan="2"></td>
              <td>Subtotal</td>
              <td>{formatCurrency(subtotal)}</td>
            </tr>
            {categoryName === 'materials' && (
              <>
                <tr className="tax-row">
                  <td colSpan="2"></td>
                  <td>
                    Tax & Shipping
                    <input
                      type="number"
                      value={Math.round(editValues.tax_rate * 10000) / 100}
                      onChange={(e) => handleTaxRateChange(e.target.value)}
                      min="0"
                      className="form-control tax-input"
                    />
                    %
                  </td>
                  <td>{formatCurrency(tax)}</td>
                </tr>
                <tr className="total-row">
                  <td colSpan="2"></td>
                  <td>Total</td>
                  <td>{formatCurrency(totalWithTax)}</td>
                </tr>
              </>
            )}
          </tfoot>
        </table>
      </div>
    );
  };

  const renderEditableCategories = (categoriesData, sectionName = '') => (
    <>
      {CATEGORY_ORDER.map((categoryName) => {
        const categoryData = categoriesData[categoryName];
        if (categoryData) {
          return (
            <div key={`${sectionName}-${categoryName}`}>
              {renderEditableCategorySection(categoryData, categoryName)}
            </div>
          );
        }
        return null;
      })}
    </>
  );

  return (
    <div className="categories">
      {categories && renderEditableCategories(categories)}
      {sections &&
        Object.entries(sections).map(([sectionName, sectionData]) => (
          <div key={sectionName} className="section">
            <h2>{sectionName}</h2>
            {renderEditableCategories(sectionData.categories, sectionName)}
          </div>
        ))}
    </div>
  );
};

export default EditableCategories;
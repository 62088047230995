/* Used by: App.js */

// src/modules/EmployeePage/EmployeePage.js
import { useSelector } from 'react-redux';
import React, { useState, useEffect, useCallback } from 'react';
import API_BASE_URL from '../../constants/apiConfig';
import EmployeeTable from './UsersTable';
import EditableEmployeeTable from './EditableUsersTable';

const useEmployeeEditState = (initialEmployees, setInitialEmployees) => {
  const customerId = useSelector((state) => state.user.user?.customer_id);
  const [isEditable, setIsEditable] = useState(false);
  const [employees, setEmployees] = useState(initialEmployees);
  const [newEmployees, setNewEmployees] = useState([]);

  useEffect(() => {
    setEmployees(initialEmployees);
  }, [initialEmployees]);

  const enterEditMode = () => {
    setIsEditable(true);
  };

  const cancelEditMode = () => {
    setEmployees([...initialEmployees]);
    setNewEmployees([]);
    setIsEditable(false);
  };

  const saveEditMode = async () => {
    try {
      // Update existing employees
      await Promise.all(
        employees.map((employee) =>
          fetch(`${API_BASE_URL}/api/employees/${employee.phone_number}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...employee, customer_id: customerId }),
          })
        )
      );
  
      // Add new employees
      await Promise.all(
        newEmployees.map((employee) =>
          fetch(`${API_BASE_URL}/api/employees`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...employee, customer_id: customerId }),
          })
        )
      );
  
      // Delete removed employees
      const removedEmployees = initialEmployees.filter(
        (initialEmployee) =>
          !employees.some((employee) => employee.phone_number === initialEmployee.phone_number)
      );
      await Promise.all(
        removedEmployees.map((employee) =>
          fetch(`${API_BASE_URL}/api/employees/${employee.phone_number}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ customer_id: customerId }),
          })
        )
      );
  
      setInitialEmployees([...employees, ...newEmployees]);
      setNewEmployees([]);
      setIsEditable(false);
    } catch (error) {
      console.error('Error saving employees:', error);
    }
  };

  const addNewLine = () => {
    setNewEmployees([
      ...newEmployees,
      {
        first_name: '',
        last_name: '',
        phone_number: '',
        language: 'English',
      },
    ]);
  };

  return {
    isEditable,
    employees,
    setEmployees,
    newEmployees,
    setNewEmployees,
    enterEditMode,
    cancelEditMode,
    saveEditMode,
    addNewLine,
  };
};

const EmployeePage = () => {
    const customerId = useSelector((state) => state.user.user?.customer_id);
    const [initialEmployees, setInitialEmployees] = useState([]);
  
    const fetchEmployees = useCallback(async () => {
      if (customerId) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/employees?customer_id=${customerId}`);
          const data = await response.json();
          setInitialEmployees(data);
        } catch (error) {
          console.error('Error fetching employees:', error);
        }
      }
    }, [customerId]);
  
    useEffect(() => {
      fetchEmployees();
    }, [fetchEmployees, customerId]);
  
    const {
      isEditable,
      employees,
      setEmployees,
      newEmployees,
      setNewEmployees,
      enterEditMode,
      cancelEditMode,
      saveEditMode,
      addNewLine,
    } = useEmployeeEditState(initialEmployees, setInitialEmployees);
  
    const removeEmployee = (phoneNumber) => {
      setEmployees(employees.filter((employee) => employee.phone_number !== phoneNumber));
    };
  
    return (
        <div className="container mt-4">
          <div className="card shadow">
            <div className="card-body">
              <h1>Users</h1>
              <div className="row">
                <div className="col-md-12">
                  {isEditable ? (
                    <EditableEmployeeTable
                      employees={employees}
                      setEmployees={setEmployees}
                      newEmployees={newEmployees}
                      setNewEmployees={setNewEmployees}
                      removeEmployee={removeEmployee}
                    />
                  ) : (
                    <EmployeeTable employees={employees} />
                  )}
                  {!isEditable && (
                    <button className="btn btn-primary mt-3" onClick={enterEditMode}>
                      Edit Users
                    </button>
                  )}
                  {isEditable && (
                    <div>
                      <button className="btn btn-secondary mt-3 me-2" onClick={addNewLine}>
                        Add New Line
                      </button>
                      <button className="btn btn-primary mt-3 me-2" onClick={saveEditMode}>
                        Save
                      </button>
                      <button className="btn btn-secondary mt-3" onClick={cancelEditMode}>
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };
  
  export default EmployeePage;
import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  SET_DEVELOPER_LOGGED_IN,
  CLEAR_DEVELOPER_LOGGED_IN
} from '../actions/userActions';

const initialState = {
  user: null,
  isDeveloperLoggedIn: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGGED_IN:
      return {
        ...state,
        user: action.payload,
      };
    case USER_LOGGED_OUT:
      return {
        ...state,
        user: null,
      };
    case SET_DEVELOPER_LOGGED_IN:
      return {
        ...state,
        isDeveloperLoggedIn: true,
      };
    case CLEAR_DEVELOPER_LOGGED_IN:
      return {
        ...state,
        isDeveloperLoggedIn: false,
        user: null,
      };
    default:
      return state;
  }
};

export default userReducer;
import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import API_BASE_URL from '../../constants/apiConfig';

const useFilteredChangeOrderStats = (customerId) => {
  const [timeRange, setTimeRange] = useState('all');
  const [allChangeOrders, setAllChangeOrders] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllChangeOrders = async () => {
      if (customerId) {
        try {
          const response = await axios.get(`${API_BASE_URL}/all-change-orders`, {
            params: { customer_id: customerId },
          });

          if (response.data.success) {
            const ordersWithDates = response.data.data.map(order => ({
              ...order,
              createdAt: order.created_at ? new Date(order.created_at) : new Date()
            }));
            setAllChangeOrders(ordersWithDates);
          } else {
            throw new Error(response.data.message);
          }
        } catch (error) {
          console.error('Error fetching all change orders:', error);
          setError(error);
          setAllChangeOrders([]);
        }
      }
    };

    fetchAllChangeOrders();
  }, [customerId]);

  const filterChangeOrders = (range, orders) => {
    if (range === 'all') return orders;

    const maxDaysOld = parseInt(range);

    return orders.filter(order => {
      const daysOld = parseInt(order.daysOld);
      return !isNaN(daysOld) && daysOld <= maxDaysOld;
    });
  };

  const filteredOrders = useMemo(() => filterChangeOrders(timeRange, allChangeOrders), [timeRange, allChangeOrders]);

  const filteredChangeOrdersStats = useMemo(() => {
    const stats = {
      Total: { count: 0, totalValue: 0 },
      Unexecuted: { count: 0, totalValue: 0 },
      Executed: { count: 0, totalValue: 0 },
      Cancelled: { count: 0, totalValue: 0 },
    };
  
    if (filteredOrders.length === 0) {
      return stats;
    }
  
    filteredOrders.forEach(order => {
      if (order.status !== 'Incomplete') {
        const value = parseFloat(order.amount) || 0;
        
        stats.Total.count++;
        stats.Total.totalValue += value;
    
        if (order.status === 'Unexecuted') {
          stats.Unexecuted.count++;
          stats.Unexecuted.totalValue += value;
        } else if (order.status === 'Executed') {
          stats.Executed.count++;
          stats.Executed.totalValue += value;
        } else if (order.status === 'Cancelled' || order.status === 'Canceled') {
          stats.Cancelled.count++;
          stats.Cancelled.totalValue += value;
        }
      }
    });
  
    return stats;
  }, [filteredOrders]);

  const pieChartData = useMemo(() => {
    if (filteredChangeOrdersStats.Total.count === 0) {
      // Return dummy data for empty state with zero counts
      return [
        { name: 'Unexecuted', value: 1, count: 0, totalValue: '0.00' },
        { name: 'Executed', value: 1, count: 0, totalValue: '0.00' },
        { name: 'Cancelled', value: 1, count: 0, totalValue: '0.00' }
      ];
    }
  
    return Object.entries(filteredChangeOrdersStats)
      .filter(([key]) => key !== 'Total')
      .map(([name, { count, totalValue }]) => ({ 
        name, 
        value: count,
        count: count,
        totalValue: totalValue.toFixed(2)
      }));
  }, [filteredChangeOrdersStats]);

  const handleTimeRangeChange = (newRange) => {
    console.log('Time range changed to:', newRange);
    setTimeRange(newRange);
  };

  const regions = useMemo(() => {
    return [...new Set(allChangeOrders.map(order => order.region))].sort();
  }, [allChangeOrders]);

  const statuses = useMemo(() => {
    return [...new Set(allChangeOrders.map(order => order.status))];
  }, [allChangeOrders]);

  const getAllChangeOrdersStats = useMemo(() => {
    const stats = {
      Unexecuted: 0,
      Executed: 0,
      Cancelled: 0,
      Total: 0,
    };

    allChangeOrders.forEach(order => {
      if (order.status !== 'Incomplete') {
        stats.Total++;
        if (order.status === 'Unexecuted') stats.Unexecuted++;
        else if (order.status === 'Executed') stats.Executed++;
        else if (order.status === 'Cancelled' || order.status === 'Canceled') stats.Cancelled++;
      }
    });

    return stats;
  }, [allChangeOrders]);

  return { 
    timeRange, 
    filteredChangeOrdersStats, 
    pieChartData, 
    handleTimeRangeChange,
    regions,
    statuses,
    error,
    getAllChangeOrdersStats
  };
};

export default useFilteredChangeOrderStats;
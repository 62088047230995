// src/modules/shared/ChangeOrdersTable/ChangeOrdersTable.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useUpdateStatus from './useUpdateStatus';
import ConfirmationModal from './ConfirmationModal';
import './ChangeOrdersTable.css';
import axios from 'axios';
import API_BASE_URL from '../../../constants/apiConfig';

const ChangeOrdersTable = ({ changeOrders: initialChangeOrders, status, excludeJobNames = false }) => {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [changeOrders, setChangeOrders] = useState(initialChangeOrders);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [hoveredCor, setHoveredCor] = useState(null);
  const [tempStatuses, setTempStatuses] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [confirmButtonText, setConfirmButtonText] = useState('');
  const [confirmAction, setConfirmAction] = useState(() => () => {});
  const [showCancelButton, setShowCancelButton] = useState(true);
  const { updateStatus, deleteChangeOrder } = useUpdateStatus(user);
  
  const statusOptions = ['Executed', 'Unexecuted', 'Cancelled', 'Delete'];

  const getColumnConfig = (status) => {
    let config = {
      includedColumns: [],
      columnWidths: []
    };

    switch (status) {
      case 'Incomplete':
        config.includedColumns = ['complete', 'corNumber', 'description', 'status', 'amount'];
        config.columnWidths = ['8%', '6%', '48%', '14%', '14%', '10%'];
        break;
      case 'Unexecuted':
        config.includedColumns = ['daysOld', 'corNumber', 'description', 'status', 'amount'];
        config.columnWidths = ['8%', '6%', '54%', '16%', '16%'];
        break;
      default:
        config.includedColumns = ['corNumber', 'description', 'status', 'amount'];
        config.columnWidths = ['6%', '60%', '17%', '17%'];
    }

    if (!excludeJobNames) {
      config.includedColumns.splice(3, 0, 'jobName');
      config.columnWidths = config.columnWidths.map(width => `${parseFloat(width) * 0.85}%`);
      config.columnWidths.splice(3, 0, '15%');
    }

    return config;
  };

  const { includedColumns, columnWidths } = getColumnConfig(status);

  const handleCorSelection = (corNumber, isOldCor) => {
    if (isOldCor) {
      setShowModal(true);
      setModalMessage("Information unavailable. Change Order was created outside of assistant.");
      setConfirmButtonText("Close");
      setConfirmAction(() => () => setShowModal(false));
      setShowCancelButton(false);
    } else {
      navigate(`/change-order/${corNumber}`);
    }
  };

  const handleSelectJob = (jobName) => {
    const formattedJobName = jobName.replace(/\s+/g, '-');
    navigate(`/job/${formattedJobName}`);
  };

  const handleStatusChange = (corNumber, newStatus, daysOld) => {
    if (newStatus === 'Delete') {
      const message = daysOld <= 1
        ? 'This change order has existed for less than 24 hours so your credit will be refunded. Deleting this change order cannot be undone. Do you wish to proceed?'
        : 'This change order has existed for longer than 24 hours so your credit will not be refunded. Do you wish to proceed?';

      setModalMessage(message);
      setConfirmButtonText('Delete');
      setShowCancelButton(true);
      setConfirmAction(() => () => {
        if (daysOld <= 1) {
          deleteChangeOrder(corNumber).then(() => {
            setChangeOrders(prevOrders => prevOrders.filter(order => order.corNumber !== corNumber));
            setShowModal(false);
          });
        } else {
          setTempStatuses(currentStatuses => ({ ...currentStatuses, [corNumber]: "Loading..." }));
          updateStatus('changeOrder', corNumber, 'Deleted').then(() => {
            setTempStatuses(currentStatuses => ({ ...currentStatuses, [corNumber]: 'Deleted' }));
            setShowModal(false);
          }).catch((error) => {
            console.error("Failed to update status:", error);
            setTempStatuses(currentStatuses => ({ ...currentStatuses, [corNumber]: 'Error' }));
            alert("Failed to update status. Please try again.");
          });
        }
      });
      setShowModal(true);
    } else {
      const originalStatus = changeOrders.find(order => order.corNumber === corNumber)?.status;
      setTempStatuses(currentStatuses => ({ ...currentStatuses, [corNumber]: "Loading..." }));

      updateStatus('changeOrder', corNumber, newStatus).then(() => {
        setTempStatuses(currentStatuses => ({ ...currentStatuses, [corNumber]: newStatus }));
      }).catch((error) => {
        console.error("Failed to update status:", error);
        setTempStatuses(currentStatuses => ({ ...currentStatuses, [corNumber]: originalStatus }));
        alert("Failed to update status. Please try again.");
      });
    }
  };

  const handleMouseEnter = (corNumber) => {
    setHoveredRow(corNumber);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleMouseEnterCor = (corNumber) => {
    setHoveredCor(corNumber);
  };

  const handleMouseLeaveCor = () => {
    setHoveredCor(null);
  };

  const calculateTotalAmount = () => {
    return changeOrders.reduce((total, order) => total + order.amount, 0);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    }).format(amount);
  };

  const getWidthStyle = (index) => ({
    width: columnWidths && columnWidths.length > index ? columnWidths[index] : 'auto'
  });

  const handleDownloadPDF = async () => {
    try {
      console.log("Sending request to generate PDF with change orders:", changeOrders);
      const response = await axios.post(
        `${API_BASE_URL}/generate-change-orders-pdf`,
        { 
          changeOrders: changeOrders,
          customer_id: user.customer_id
        },
        { responseType: 'blob' }
      );
  
      const contentType = response.headers['content-type'];
      if (contentType.includes('application/json')) {
        // It's an error message
        const reader = new FileReader();
        reader.onload = function() {
          const errorData = JSON.parse(reader.result);
          console.error("Error generating PDF:", errorData);
          alert(`Failed to generate PDF: ${errorData.message}`);
        };
        reader.readAsText(response.data);
      } else {
        // It's a PDF
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'change_orders.pdf';
        link.click();
      }
    } catch (error) {
      console.error("Error downloading PDF:", error);
      if (error.response) {
        if (error.response.data instanceof Blob) {
          const reader = new FileReader();
          reader.onload = function() {
            try {
              const errorData = JSON.parse(reader.result);
              console.error("Error details:", errorData);
              alert(`Failed to generate PDF: ${errorData.message}`);
            } catch (e) {
              console.error("Error parsing error response:", e);
              alert("Failed to generate PDF. Please check the console for more details.");
            }
          };
          reader.readAsText(error.response.data);
        } else {
          console.error("Response data:", error.response.data);
          alert("Failed to generate PDF. Please check the console for more details.");
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
        alert("Failed to generate PDF. No response received from the server.");
      } else {
        console.error("Error setting up request:", error.message);
        alert("Failed to generate PDF. Error setting up the request.");
      }
    }
  };

  const handleComplete = (corNumber) => {
    const message = encodeURIComponent(`Let's complete cor ${corNumber}`);
    navigate(`/assistant?message=${message}`);
  };

  const renderCell = (column, order, index) => {
    const cellStyle = { ...getWidthStyle(index), cursor: 'pointer' };

    switch (column) {
      case 'daysOld':
        const daysOldStyle = {
          ...cellStyle,
          textAlign: 'center',
          color: order.daysOld > 45 ? 'red' : 'inherit',
          fontWeight: order.daysOld > 45 ? 'bold' : 'normal',
        };
        return (
          <td
            key={'daysOld' + order.corNumber}
            style={daysOldStyle}
            onMouseEnter={() => handleMouseEnter(order.corNumber)}
            onMouseLeave={handleMouseLeave}
            className={hoveredRow === order.corNumber ? 'hover-active' : ''}
          >
            {order.daysOld}
          </td>
        );
      case 'corNumber':
        return (
          <td
            key={'corNumber' + order.corNumber}
            style={cellStyle}
            onMouseEnter={() => handleMouseEnter(order.corNumber)}
            onMouseLeave={handleMouseLeave}
            className={hoveredRow === order.corNumber ? 'hover-active' : ''}
            onClick={() => handleCorSelection(order.corNumber, order.old_cor)}
          >
            {order.corNumber}
          </td>
        );
      case 'description':
        return (
          <td
            key={'description' + order.corNumber}
            style={cellStyle}
            onMouseEnter={() => handleMouseEnter(order.corNumber)}
            onMouseLeave={handleMouseLeave}
            className={hoveredRow === order.corNumber ? 'hover-active' : ''}
            onClick={() => handleCorSelection(order.corNumber, order.old_cor)}
          >
            {order.description}
          </td>
        );
      case 'jobName':
        return (
          <td
            key={'jobName' + order.corNumber}
            style={cellStyle}
            onMouseEnter={() => handleMouseEnterCor(order.corNumber)}
            onMouseLeave={handleMouseLeaveCor}
            className={hoveredCor === order.corNumber ? 'hover-active' : ''}
            onClick={() => handleSelectJob(order.jobName)}
          >
            {order.jobName}
          </td>
        );
      case 'status':
        const displayStatus = tempStatuses.hasOwnProperty(order.corNumber) ? tempStatuses[order.corNumber] : order.status;
        let currentStatusOptions = statusOptions;
        
        if (!currentStatusOptions.includes(displayStatus)) {
          currentStatusOptions = [displayStatus, ...currentStatusOptions];
        }

        if (displayStatus === 'Deleted' && !currentStatusOptions.includes('Deleted')) {
          currentStatusOptions.push('Deleted');
        }
        
        return (
          <td
            key={'status' + order.corNumber}
            style={cellStyle}
            className="status-cell"
          >
            <select
              value={displayStatus}
              onChange={(e) => handleStatusChange(order.corNumber, e.target.value, order.daysOld)}
              style={{ width: '100%' }}
              disabled={displayStatus === "Loading..."}
            >
              {currentStatusOptions.map((status, index) => (
                <option key={index} value={status}>{status}</option>
              ))}
            </select>
          </td>
        );
      case 'amount':
        return (
          <td key={'amount' + order.corNumber}>
            {formatCurrency(order.amount)}
          </td>
        );
      case 'complete':
        return (
          <td key={'complete' + order.corNumber} style={cellStyle}>
            <button
              className="btn btn-primary btn-sm"
              onClick={(e) => {
                e.stopPropagation();
                handleComplete(order.corNumber);
              }}
            >
              Complete
            </button>
          </td>
        );
      default:
        return <td key={column + order.corNumber} style={cellStyle}></td>;
    }
  };

  return (
    <div>
      <div className="table-header">
        <h3>{status}</h3>
        <button
          onClick={handleDownloadPDF}
          className="btn btn-secondary export-button"
        >
          Export COR Log
        </button>
      </div>
      <table className="table">
        <thead>
          <tr>
            {includedColumns.map((column, index) => (
              <th key={column} style={getWidthStyle(index)}>
                {
                  column === 'daysOld' ? 'Days Old' :
                  column === 'corNumber' ? 'COR #' :
                  column === 'description' ? 'Description' :
                  column === 'jobName' ? 'Job Name' :
                  column === 'status' ? 'Status' :
                  column === 'amount' ? 'Amount' :
                  column === 'complete' ? 'Action' : column
                }
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {changeOrders.map((order) => (
            <tr key={order.corNumber}>
              {includedColumns.map((column, index) => renderCell(column, order, index))}
            </tr>
          ))}
        </tbody>
        {changeOrders.length > 0 && (
          <tfoot>
            <tr>
              <td colSpan={includedColumns.length - 1} style={{ border: 'none' }}></td>
              <td style={{ fontWeight: 'bold' }}>
                Total: {formatCurrency(calculateTotalAmount())}
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      {changeOrders.length === 0 && <div>No change orders to display.</div>}
      <ConfirmationModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={confirmAction}
        message={modalMessage}
        confirmButtonText={confirmButtonText}
        showCancelButton={showCancelButton}
      />
    </div>
  );
};

export default ChangeOrdersTable;
// src/modules/ChangeOrderComponents/Totals/EditableTotals.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateEditValues } from '../redux/changeOrderActions';
import './Totals.css';

const EditableTotals = () => {
  const dispatch = useDispatch();
  const { editValues } = useSelector((state) => state.changeOrder);
  const { total_costs, profit_and_overhead_rate } = editValues;
  const { base_cost } = total_costs;

  const wholeNumberPandO = Math.round(profit_and_overhead_rate * 10000) / 100;

  const formatCurrency = (amount) => {
    amount = amount || 0;
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const handleProfitAndOverheadChange = (value) => {
    const parsedValue = parseFloat(value);
    dispatch(updateEditValues({
      ...editValues,
      profit_and_overhead_rate: parsedValue / 100,
    }));
  };

  // Calculate the P&O amount
  const poAmount = base_cost * profit_and_overhead_rate;

  // Calculate the total cost
  const totalCost = base_cost + poAmount;

  return (
    <div className="totals">
      <table className="table totals-table">
        <tbody>
          <tr className="subtotals-row">
            <th>Subtotals</th>
            <td>{formatCurrency(base_cost)}</td>
          </tr>
          <tr className="profit-overhead-row">
            <th>
              Profit & Overhead
              <input
                type="number"
                value={wholeNumberPandO}
                onChange={(e) => handleProfitAndOverheadChange(e.target.value)}
                className="form-control"
                min="0"
              />
              %
            </th>
            <td>{formatCurrency(poAmount)}</td>
          </tr>
          <tr className="totals-total-row">
            <th>Total Cost</th>
            <td>{formatCurrency(totalCost)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EditableTotals;
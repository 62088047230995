import React, { useState, useEffect } from 'react';
import API_BASE_URL from '../../constants/apiConfig';

const PlanManager = ({ customerId }) => {
  const [currentPlan, setCurrentPlan] = useState(null);
  const [availablePlans, setAvailablePlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchPlanData = async () => {
      if (customerId) {
        setIsLoading(true);
        try {
          const [currentPlanResponse, availablePlansResponse] = await Promise.all([
            fetch(`${API_BASE_URL}/api/current-plan?customer_id=${customerId}`),
            fetch(`${API_BASE_URL}/api/available-plans?customer_id=${customerId}`)
          ]);

          if (!currentPlanResponse.ok || !availablePlansResponse.ok) {
            throw new Error('Failed to fetch plan data');
          }

          const currentPlanData = await currentPlanResponse.json();
          const availablePlansData = await availablePlansResponse.json();

          setCurrentPlan(currentPlanData);
          setAvailablePlans(availablePlansData);
        } catch (error) {
          console.error('Error fetching plan data:', error);
          setError('Failed to load plan information. Please try again later.');
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchPlanData();
  }, [customerId]);

  const handleChangePlan = async (planId) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/change-plan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ customer_id: customerId, plan_id: planId }),
      });

      if (!response.ok) {
        throw new Error('Failed to change plan');
      }

      const updatedPlan = await response.json();
      setCurrentPlan(updatedPlan);
    } catch (error) {
      console.error('Error changing plan:', error);
      setError('Failed to change plan. Please try again later.');
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (isLoading) {
    return <div>Loading plan information...</div>;
  }

  if (error) {
    return <div style={{ color: 'red' }}>{error}</div>;
  }

  return (
    <div className="plan-manager">
      <h3 className="card-title">Plan Management</h3>
      {currentPlan && currentPlan.planName ? (
        <div>
          <h4>Current Plan</h4>
          <p><strong>Plan Name:</strong> {currentPlan.planName}</p>
          <p><strong>Monthly Credits:</strong> {currentPlan.creditsPerMonth || 'N/A'}</p>
          <p>
            <strong>Price: </strong> 
            {currentPlan.price !== undefined 
              ? `$${currentPlan.price.toFixed(2)}/${currentPlan.planName === "Pay-as-you-go" ? "credit" : "month"}`
              : 'N/A'}
          </p>
          <button onClick={openModal} className="btn btn-primary">
            Change Plan
          </button>
        </div>
      ) : (
        <div>
          <p>You are not currently subscribed to any plan.</p>
          <button onClick={openModal} className="btn btn-primary">
            Select Plan
          </button>
        </div>
      )}

      {isModalOpen && (
        <div className="modal" style={{display: 'block', backgroundColor: 'rgba(0,0,0,0.5)'}}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Available Plans</h5>
                <button type="button" className="close" onClick={closeModal}>
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {availablePlans.map((plan) => (
                  <div key={plan.id} className="card mb-3">
                    <div className="card-body">
                      <h5 className="card-title">{plan.name}</h5>
                      {plan.description && <p className="card-text">{plan.description}</p>}
                      {plan.name === "Pay-as-you-go" ? (
                        <p className="card-text">
                          Price: ${plan.pricePerCredit !== undefined ? plan.pricePerCredit.toFixed(2) : 'N/A'}/credit
                        </p>
                      ) : (
                        <>
                          <p className="card-text">Credits: {plan.creditsPerMonth || 'N/A'} per {plan.interval || 'month'}</p>
                          <p className="card-text">
                            Price: ${plan.price !== undefined ? plan.price.toFixed(2) : 'N/A'}/{plan.interval || 'month'}
                          </p>
                        </>
                      )}
                      {plan.trialPeriodDays && (
                        <p className="card-text">Trial period: {plan.trialPeriodDays} days</p>
                      )}
                      <button 
                        onClick={() => {
                          handleChangePlan(plan.id);
                          closeModal();
                        }}
                        className="btn btn-primary"
                        disabled={currentPlan && currentPlan.planName === plan.name}
                      >
                        {currentPlan && currentPlan.planName === plan.name ? 'Current Plan' : 'Select Plan'}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanManager;
/* Used by: modules/Assistant/Assistant.js */

// src/modules/LoadingDots.js
import React from 'react';
import './LoadingDots.css';

const LoadingDots = () => {
  return (
    <div className="loading-dots">
      <div className="dot dot1"></div>
      <div className="dot dot2"></div>
      <div className="dot dot3"></div>
    </div>
  );
};

export default LoadingDots;
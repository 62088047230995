/* Used by: modules/LandingPage/LandingPage.js */

// src/modules/LandingPage/SignUpService.js
import axios from 'axios';
import API_BASE_URL from '../../constants/apiConfig';

export const signUp = async (formData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/signup`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return response;
  } catch (error) {
    console.error('Error during sign up:', error);
    throw error;
  }
};
/* Used by: modules/ChangeOrderComponents/ChangeOrder.js */

// src/modules/ChangeOrderComponents/Totals/Totals.js
import React from 'react';
import { useSelector } from 'react-redux';
import './Totals.css';

const Totals = () => {
  const { changeOrderDetails } = useSelector((state) => state.changeOrder);
  const { total_costs, profit_and_overhead_rate } = changeOrderDetails.bigDict;

  const formatCurrency = (amount) => {
    amount = amount || 0;
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };

  const profitAndOverheadDisplay = profit_and_overhead_rate ? `${(profit_and_overhead_rate * 100).toFixed(2)}%` : '0%';

  return (
    <div className="totals">
      <table className="table totals-table">
        <tbody>
          <tr className="subtotals-row">
            <th>Subtotals</th>
            <td>{formatCurrency(total_costs.base_cost)}</td>
          </tr>
          <tr className="profit-overhead-row">
            <th>Profit & Overhead ({profitAndOverheadDisplay})</th>
            <td>{formatCurrency(total_costs.po_amount)}</td>
          </tr>
          <tr className="totals-total-row">
            <th>Total Cost</th>
            <td>{formatCurrency(total_costs.final_total)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Totals;